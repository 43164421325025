"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ADJOINED", {
  enumerable: true,
  get: function () {
    return _input.ADJOINED;
  }
});
Object.defineProperty(exports, "SIZE", {
  enumerable: true,
  get: function () {
    return _input.SIZE;
  }
});
Object.defineProperty(exports, "STATE_CHANGE_TYPE", {
  enumerable: true,
  get: function () {
    return _input.STATE_CHANGE_TYPE;
  }
});
var _input = require("../input");