import { createTheme } from 'baseui';

const primitives = {
  // slate
  accent: '#337ab7', // this is btn.primary color in our bootstrap css
  accent0: '#EDEEEF',
  accent100: '#CCE2EB',
  accent200: '#99C5D7',
  accent300: '#66A9C2',
  accent400: '#338CAE',
  accent500: '#006F9A',
  accent600: '#00597B',
  accent700: '#00435C',
  accent800: '#002C3E',
  accent900: '#00161F',
};

const overrides = {
  colors: {
    buttonPrimaryFill: primitives.accent,
    buttonPrimaryText: primitives.accent0,
    buttonPrimaryHover: primitives.accent600,
    buttonPrimaryActive: primitives.accent700,
    buttonPrimarySelectedFill: primitives.accent200,
    buttonPrimarySelectedText: primitives.accent,
    buttonPrimarySpinnerForeground: primitives.accent700,
    buttonPrimarySpinnerBackground: primitives.accent300,
  },
};
export const theme = createTheme(overrides);

// Override styles for our Tab component
export const TabOverride = {
  style: {
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    color: 'gray',
  },
};
export const TabHighlightOverride = {
  style: {
    backgroundColor: '#abd037',
  },
};

// Override style for TabPanel, this will remove any padding of the container that display content of a tab
export const TabPanelNoPadding = {
  style: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

/**
 * This variable is used to pass in popperOptions props of <StatefulTooltip /> component of baseweb when the tooltip content is too long
 * and wider than the parent's width
 * Example:
 *<div style={{width: '100px'}}> <-- Because the parent's width is set at 100px, the tooltip will have to stay within it
 *  <StatefulTooltip
 *    ignoreBoundary  <--- This is required to properly display tooltip position without considering the parent's width
 *    popperOptions={PoppoverPropsIgnoreBoundary} <-- This will remove the warning in console
 *    ...
 *  >
 *    <Element />
 *  </StatefulTooltip>
 * </div>
 */
export const PoppoverPropsIgnoreBoundary = {
  modifiers: {
    hide: { enabled: false },
    preventOverflow: { enabled: false },
  },
};
